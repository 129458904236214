<i18n>
{
  "en": {
    "browser_title": "Help",
    "heading": "Help",
    "table_contents": "Table of Contents",
    "expand_all": "Expand All"
  },
  "ja": {
    "browser_title": "ヘルプ",
    "heading": "ヘルプ",
    "table_contents": "目次",
    "expand_all": "全ての回答を開く"
  }
}
</i18n>

<template>
<main>
  <vue-headful v-bind:title="dynamicTitle" />
  <div class="Faq">
    <div class="contents">
      <h1>{{ locale === 'en' ? 'FAQ' : 'ヘルプ / よくある質問' }}</h1>

      <!-- TOC (only display if there's more than 1 set of faq types) -->
      <section class="section" v-if="faqTypes.length > 1">
        <h3>{{ locale === 'en' ? 'Table of Contents' : '目次' }}</h3>
        <ul class="list-style list-style--disc">
          <li v-for="type in faqTypes" :key="type.id"><a :href="`#faq-${type.id}`" v-smooth-scroll="{ duration: 500, offset: -(appHeaderHeight) }">{{ type.name }}</a></li>
        </ul>
      </section>

      <!-- FAQs -->
      <div v-for="type in faqTypes" :key="type.id">
        <section :id="`faq-${type.id}`" class="section">
          <div class="heading">
            <h2>{{ type.name }}</h2>
            <button class="button-flat button-icon--right button-medium" @click="expandAll(`faq-${type.id}`)">{{ locale === 'en' ? 'Expand all' : '全ての回答をひらく' }}</button>
          </div>
          <div class="faq-panel-group">
            <div class="faq-panel" v-for="question in faqQuestions[type.id]" :key="question.id">
              <div class="panel-title panel-toggle" @click="togglePanel($event)">{{ question.question }}</div>
              <div class="panel-content" v-html="question.answer"></div>
            </div>
          </div>
        </section>
      </div>

    </div>
  </div>
</main>
</template>

<script>
/* eslint max-len: 0 */

import vueHeadful from 'vue-headful';
import APP_CONFIG from '@/appConfig';
import BifrostAPI from '@/assets/js/services/Bifrost/API';

export default {
  components: {
    'vue-headful': vueHeadful,
  },
  data() {
    return {
      dynamicTitle: '',
      faqTypes: [],
      faqQuestions: [],
    };
  },
  created() {
    // non-reactive consts
    this.appHeaderHeight = APP_CONFIG.site.headerHeight;

    // set browser title
    this.dynamicTitle = `${this.$t('browser_title')} ${APP_CONFIG.site.title[this.locale]}`;

    // we have the title, now send the page view
    this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);

    this.refreshHTML();
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    async refreshHTML() {
      const bfAPI = new BifrostAPI();

      const faqTypes = await bfAPI.getFaqTypes();
      this.faqTypes = faqTypes[this.locale];

      const faqs = await bfAPI.getFaqQuestions();
      this.faqQuestions = faqs[this.locale];
    },
    togglePanel(event) {
      const el = event.target.parentElement;
      el.classList.toggle('is-open');
    },
    expandAll(parentId) {
      const targets = document
        .getElementById(parentId)
        .getElementsByClassName('faq-panel');
      for (let i = 0; i < targets.length; i += 1) {
        targets[i].classList.toggle('is-open');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/pages/_help.scss';
</style>
